import { Preferences } from '@capacitor/preferences';
import { useContext } from '@nuxtjs/composition-api';
import { useStorage } from '@vueuse/core';
export function useLog(option) {
    const { route } = useContext();
    const logs = useStorage((option === null || option === void 0 ? void 0 : option.key) || 'debug-log', []);
    const log = (input) => {
        input.time = new Date().toISOString();
        if (!input.level)
            input.level = 'debug';
        input.route = route.value.path;
        logs.value.push(input);
        if (logs.value.length > ((option === null || option === void 0 ? void 0 : option.max) || 250)) {
            logs.value.shift();
        }
    };
    const clear = () => {
        logs.value = [];
    };
    const clearNativeLogs = async () => {
        var _a;
        await Preferences.remove({
            key: (_a = option === null || option === void 0 ? void 0 : option.key) !== null && _a !== void 0 ? _a : 'debug-log'
        });
    };
    const getNativeLogs = async () => {
        var _a;
        try {
            const { value } = await Preferences.get({
                key: (_a = option === null || option === void 0 ? void 0 : option.key) !== null && _a !== void 0 ? _a : 'debug-log'
            });
            return value ? JSON.parse(value) : [];
        }
        catch (e) {
            return [];
        }
    };
    const addNativeLogs = async (log) => {
        var _a;
        const logs = await getNativeLogs();
        logs.push(log);
        await Preferences.set({
            key: (_a = option === null || option === void 0 ? void 0 : option.key) !== null && _a !== void 0 ? _a : 'debug-log',
            value: JSON.stringify(logs)
        });
    };
    const autoCleanNativeLogs = async () => {
        var _a;
        const logs = await getNativeLogs();
        if (Array.isArray(logs) && logs.length > 50) {
            logs.splice(0, logs.length - 50);
        }
        await Preferences.set({
            key: (_a = option === null || option === void 0 ? void 0 : option.key) !== null && _a !== void 0 ? _a : 'debug-log',
            value: JSON.stringify(logs)
        });
    };
    return {
        log,
        logs,
        clear,
        getNativeLogs,
        autoCleanNativeLogs,
        clearNativeLogs,
        addNativeLogs
    };
}
