import { reactive } from '@nuxtjs/composition-api';
export default () => {
    const times = reactive(Array.from({ length: 48 }, (_, i) => {
        if (i % 2 === 0)
            return {
                hour: i / 2,
                minute: 0
            };
        else {
            return {
                hour: (i - 1) / 2,
                minute: 30
            };
        }
    }));
    return { times };
};
