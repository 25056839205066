import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
export const useHaptics = () => {
    const impact = {
        light: async () => {
            await Haptics.impact({ style: ImpactStyle.Light });
        },
        medium: async () => {
            await Haptics.impact({ style: ImpactStyle.Medium });
        },
        heavy: async () => {
            await Haptics.impact({ style: ImpactStyle.Heavy });
        }
    };
    const selection = {
        selectionStart: async () => {
            await Haptics.selectionStart();
        },
        selectionChanged: async () => {
            await Haptics.selectionChanged();
        },
        selectionEnd: async () => {
            await Haptics.selectionEnd();
        }
    };
    const notification = {
        success: async () => {
            await Haptics.notification({
                type: NotificationType.Success
            });
        },
        warning: async () => {
            await Haptics.notification({
                type: NotificationType.Warning
            });
        },
        error: async () => {
            await Haptics.notification({
                type: NotificationType.Error
            });
        }
    };
    const vibrate = async ({ duration = 500 }) => {
        await Haptics.vibrate({
            duration
        });
    };
    return {
        impact,
        vibrate,
        selection,
        notification
    };
};
