export function useJwt(token) {
    if (!process.client)
        return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((char) => '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2))
        .join(''));
    try {
        return JSON.parse(jsonPayload);
    }
    catch (error) {
        console.error('Error parsing JWT payload:', error);
        return null;
    }
}
