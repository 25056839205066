import { watch, ref } from '@nuxtjs/composition-api';
export const useRerender = (options = { debug: false }) => {
    const key = ref(0);
    let stopWatch = null;
    const refresh = () => {
        key.value = Date.now();
        if (options.debug) {
            console.log('[Refresh-key]: ', key.value);
        }
    };
    const applyWatch = ({ source, immediate, deep, onChanged, once }) => {
        if (stopWatch) {
            stopWatch();
        }
        if (!source) {
            return;
        }
        const watchCallback = (newValue, oldValue) => {
            refresh();
            if (onChanged) {
                onChanged(newValue, oldValue);
            }
            if (once && stopWatch) {
                stopWatch(); // Stop watching after the first change
            }
        };
        stopWatch = watch(source, watchCallback, { immediate, deep });
    };
    const rerenderSrc = ({ source = null, immediate = false, deep = false, onChanged, once = false }) => {
        applyWatch({ source, immediate, deep, onChanged, once });
    };
    return {
        key,
        refresh,
        rerenderSrc
    };
};
